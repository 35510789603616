import { FacebookOutlined, WhatsAppOutlined, InstagramOutlined } from '@ant-design/icons';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SocialNetworkService } from '../../config/dataService/socialNetworkService';
import { apiRoutes } from '../../utility/apiRoutes';
import { getItem } from '../../utility/localStorageControl';
import { WebchatIcon } from '../../config/icon/console-agent/WebchatIcon';
import { TwitterIcon } from '../../config/icon/console-chat/TwitterIcon';

export const ACTIVE = 'active';
export const INACTIVE = 'inactive';
export const PAUSED = 'paused';
export const WHATSAPP = 1;
export const FACEBOOK = 2;
export const WEBCHAT = 3;
export const checkSocialNetwork = {
  WHATSAPP: {
    icon: <WhatsAppOutlined />,
    className: 'badge-whatsapp',
    color: '#7DD955',
    limit: 4096,
  },
  FACEBOOK: {
    icon: <FacebookOutlined />,
    className: 'badge-facebook',
    color: '#3D9AF0',
    limit: 2000,
  },
  TWITTER: {
    icon: <TwitterIcon />,
    className: 'badge-twitter',
    color: '#FF00BA',
    limit: 2000,
  },
  WEBCHAT: {
    icon: <WebchatIcon />,
    className: 'badge-facebook',
    color: 'red',
    limit: 2000,
  },
  INSTAGRAM: {
    icon: <InstagramOutlined />,
    className: 'badge-instagram',
    color: '#FF00BA',
    limit: 2000,
  },
};

export const checkMotivo = {
  1: {
    icon: <WhatsAppOutlined />,
    name: 'Motivo 1',
    className: 'badge-whatsapp',
    color: 'blue',
  },
  2: {
    icon: <FacebookOutlined />,
    name: 'Motivo 2',
    className: 'badge-facebook',
    color: 'red',
  },
  3: {
    icon: <FacebookOutlined />,
    name: 'Motivo 3',
    className: 'badge-facebook',
    color: 'yellow',
  },
};

export const checkActiveUser = users => {
  const { consoleState } = users;
  // console.log('console state check ', consoleState);
  return consoleState; // !== INACTIVE;
};

export function handleActive(active) {
  return async () => {
    const userId = getItem('userId');
    console.log('handle active', active);

    try {
      await SocialNetworkService.put(apiRoutes.socialCenter.changeAgentStatus(userId, active));
    } catch (err) {
      console.error(err.response);
    }
  };
}
const axios = require('axios');

export const getFilesAxios = async url => {
  let data = [];
  await axios
    .get(`${url}`, { responseType: 'blob' })
    .then(function(response) {
      const blob = response.data;
      let file = [];
      const { type } = blob;
      if (type.includes(`image`)) {
        file = new File([blob], 'image.jpg', { lastModified: 1534584790000, type });
        console.log('image');
      } else if (type.includes(`application`)) {
        file = new File([blob], 'file.pdf', { lastModified: 1534584790000, type });
        console.log('file');
      } else if (type.includes(`mp4`)) {
        file = new File([blob], 'video.mp4', { lastModified: 1534584790000, type });
      }
      const uid2 = {
        uid: uuidv4(),
      };
      const finalResult = Object.assign(file, uid2);
      data = finalResult;
    })
    .catch(function(error) {
      console.log(error);
    })
    .then(function() {});
  return data;
};
